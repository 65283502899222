import React, { useLayoutEffect, useEffect } from 'react'
import type { AppProps } from 'next/app'
import pclnExpt from '@pcln/experimentation'
import { ThemeProvider } from 'pcln-design-system'
import { tag, mark } from '@pcln/measure'
import ErrorBoundary from '@pcln/error-boundary'
import BootstrapDataContext from '@/context/BootstrapDataContext'
import ExperimentContext from '@/context/ExperimentContext'
import analytics from '@/shared-utils/analytics'
import {
  BaseSetiResponseEvent,
  BootstrapData,
  Referral,
  Webstats
} from '@/types'
import GlobalStyle from '@/components/GlobalStyles.styled'
import handleErrorBoundaryLogging from '@/shared-utils/handle-error-boundary-logging'

const PRODUCT_CODES = {
  flights: 1,
  hotels: 5,
  packages: 17,
  home: 16,
  vip: 16,
  join: 16,
  ccpa: 16
} as const

if (typeof window !== 'undefined') {
  window.addEventListener('error', event => {
    const scriptErrorRegex = /script error/i
    const errorEvent = {
      message: event.message,
      error: event.error,
      columnNo: event.colno,
      lineNo: event.lineno
    }
    if (scriptErrorRegex.test(event.message)) {
      analytics.fireEvent({
        category: 'script_error',
        ...errorEvent
      })
    } else {
      analytics.fireEvent({
        category: 'react root component load complete',
        ...errorEvent
      })
    }
  })
}

function Redirect() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = '/pages/homepage_lite.html'
    }
  }, [])
  return null
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const bootstrapData = pageProps?.bootstrapData as BootstrapData | undefined
  useEffect(() => {
    mark('next-landing visual paint complete')
  })

  useLayoutEffect(() => {
    if (bootstrapData) {
      tag('appName', bootstrapData.appName || 'next-landing')
      tag('appVersion', bootstrapData.appVersion)
      tag('signInStatus', bootstrapData.signInStatus)
    }
  }, [bootstrapData])

  useLayoutEffect(() => {
    function clearBatchHandler() {
      pclnExpt.clearBatch()
    }
    function initializePclnExpt({
      fullSETI = [],
      isMobile = false,
      key: productCode,
      referral,
      webstats
    }: {
      fullSETI: BaseSetiResponseEvent[]
      isMobile: boolean
      key: keyof typeof PRODUCT_CODES
      referral: Referral
      webstats: Webstats
    }): void {
      const webAppMetadata = {
        appCode: isMobile ? 'MOBILEWEB' : 'DESKTOPWEB',
        clientIP: webstats.resolvedClientIP,
        globalPartnerCode: 'PCLN',
        partnerCode: 'pcln',
        productID: PRODUCT_CODES[productCode],
        productSubType: 'ALL',
        referralClickID: referral.clickId,
        referralID: referral.id,
        referralSourceID: referral.sourceId,
        serverTimestamp: webstats.requestTime
      }
      pclnExpt.initialize(fullSETI, webAppMetadata, {
        useBatching: true
      })
    }
    if (bootstrapData) {
      initializePclnExpt(bootstrapData)
      window.addEventListener('beforeunload', clearBatchHandler)
    }
    return () => {
      if (bootstrapData) {
        window.removeEventListener('beforeunload', clearBatchHandler)
      }
    }
  }, [bootstrapData])

  return (
    <ErrorBoundary
      log={handleErrorBoundaryLogging}
      payload={{
        severity: 1,
        componentName: '_app.tsx',
        message: 'Error raised by React ErrorBoundary'
      }}
      fallback={<Redirect />}
    >
      <ThemeProvider>
        <GlobalStyle />
        {bootstrapData ? (
          <BootstrapDataContext.Provider value={bootstrapData}>
            <ExperimentContext.Provider value={bootstrapData.seti}>
              <Component {...pageProps} />
            </ExperimentContext.Provider>
          </BootstrapDataContext.Provider>
        ) : (
          <Component {...pageProps} />
        )}
      </ThemeProvider>
    </ErrorBoundary>
  )
}
