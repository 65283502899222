import analytics from './analytics'

function handleErrorBoundaryLogging(payload: {
  error: Error
  componentStack: string
  severity: number
  componentName: string
  message: string
}) {
  const { error, componentStack, ...remainingProps } = payload
  analytics.logError({
    ...remainingProps,
    path: window.location.pathname,
    componentStack,
    errorStack: error?.stack || 'Logged by Error Boundary',
    errorMessage: error?.message
  })
}

export default handleErrorBoundaryLogging
