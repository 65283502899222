import { createGlobalStyle } from 'styled-components'
import { getPaletteColor } from 'pcln-design-system'

const GlobalStyle = createGlobalStyle`
  body {
  background-color: ${getPaletteColor('background.lightest')};
  margin: 0;
  padding: 0;

  &.mobile-menu-activated {
    .app-banner {
      position: relative;
      width: 98vw;
    }
  }
}
`
export default GlobalStyle
